import React, { useState, useEffect } from "react"

import NotFound from "../components/404"

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  return isMount && <NotFound />
}

export default NotFoundPage
