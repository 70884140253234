import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import Seo from "../seo"
import "./index.scss"
const NotFound = ({ _data }) => {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(not-found)/" }) {
          frontmatter {
            title1
            description1
            description2
            linkTo
            linkName
          }
        }
      }
    `)
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    frontmatter = _data
  }
  return (
    <div id="NotFound">
      <Seo title={"404"} />

      <div
        className="container NotFound  px-4  justify-content-center align-items-center col-12 col-md-9"
        data-testid="NotFound"
      >
        <h1>{frontmatter?.title1}</h1>
        <h2
          className="col-9"
          dangerouslySetInnerHTML={{ __html: frontmatter?.description1 }}
        ></h2>
        <p dangerouslySetInnerHTML={{ __html: frontmatter?.description2 }}></p>
        <Link to={frontmatter?.linkTo}>{frontmatter?.linkName}</Link>
      </div>
    </div>
  )
}

export default NotFound
